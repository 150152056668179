import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { ButtonCustom, TextCustom } from '../../atoms';
import { PreguntasForm } from '../../molecules';

//context
import { useApp, useCotizador, useForm } from '../../../hooks/others';

//api -services
import {
  apiCrearBitacora,
  apiCrearDataCRM,
  apiGetPersona,
} from '../../../services/apis';

//helpers
import {
  calcularEdad,
  capitalizarPalabra,
  changeQueryParam,
  obtenerClientID,
} from '../../../core/utils';

//css
import './styles.css';

// Validations
import { formValidDatosPersonales } from '../../../core/validations/formValidDatosPersonales';
import { validInputEmail } from '../../../core/validations';
import { typesValidation } from '../../../common/types';
import ComprandoOtroSeguroViaje from './ComprandoOtroSeguroViaje';

const PasoPersonal = () => {
  const navigate = useNavigate();

  const { recaptchaRef } = useApp();

  const {
    dataCotizar,
    setDataCotizar,
    paso,
    pasos,
    handleSiguientePaso,
    setIdCotizacion,
    setIdOportunidad,
    setDetallesCotizacion,
    switchMostrarCampos,
    setMostrarCampos,
    mostrarCampos,
    setSwitchMostrarCampos,
    handleRegresarPaso,
    isRecuperandoCotizacion,
    setIsRecuperandoCotizacion,
    setCargando,
    setPromocion,
    setComprandoOtroSeguro,
    setPasoAnterior,
    setPasoActual,
    pasoActual,
    comprandoOtroSeguro,
    pasoAnterior,
    setMaskedData,
    maskedData,
  } = useCotizador();

  const [formData, setFormData] = useState('');
  const [formDataError, setFormDataError] = useState('');
  const [modal, setModal] = useState(false);
  const [recuperoContacto, setRecuperoContacto] = useState(false);
  const [loaderInput, setLoaderInput] = useState(false);
  const [maskTel, setMaskTel] = useState('####-####');
  const [alert, setAlert] = useForm({
    title: '',
    description: '',
    severity: 'info',
    open: 'false',
  });

  useEffect(() => {
    setPasoAnterior(pasoActual);
    setPasoActual(paso);
  }, [paso, pasoActual, setPasoActual, setPasoAnterior]);

  useEffect(() => {
    const dataF = pasos[paso - 1]?.formData;
    const dataP = dataCotizar?.datosPersonales;


    if (paso === 2 && pasoAnterior !== 1) {
      if (isRecuperandoCotizacion && pasoAnterior > 3 && !comprandoOtroSeguro) {
        if (pasoAnterior !== 4) {
          setMaskedData({
            telefono: dataP.telefono,
            correo: dataP.correo,
          });
        }
        setFormData({
          ...dataF,
          ...dataP,
          telefono: dataP.telefono,
          correo: dataP.correo,
        });
        setMaskTel('****-####');
      }

      if (!isRecuperandoCotizacion && pasoAnterior > 3) {
        setFormData({
          ...dataF,
          ...dataP,
          telefono: dataP.telefono,
          correo: dataP.correo,
        });
        setFormDataError(pasos[paso - 1]?.formDataError ?? '');
      }

      if (isRecuperandoCotizacion) {
        setMaskedData({
          telefono: dataP.telefono,
          correo: dataP.correo,
        });

        setFormData({
          ...dataF,
          ...dataP,
          telefono: dataP.telefono,
          correo: dataP.correo,
        });
      }
    }
    if (comprandoOtroSeguro && pasoAnterior === 6) {
      handleValue(
        dataCotizar.datosPersonales.numeroIdentificacion,
        'numeroIdentificacion',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paso, pasoAnterior, isRecuperandoCotizacion, comprandoOtroSeguro]);

  useEffect(() => {
    if (paso === 2 && isRecuperandoCotizacion) {
      changeQueryParam('paso', paso);
    }
  }, [paso, isRecuperandoCotizacion]);

  useEffect(() => {
    if (paso === 2) {
      if (switchMostrarCampos) {
        setMostrarCampos(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [switchMostrarCampos, paso]);

  const handleValue = async (val = '', valForm) => {
    // verificamos si el numeero de identificacion para llamar al api
    if (valForm === 'numeroIdentificacion') {
      let newData = { ...formData };
      const tipoID = newData['tipoIdentificacion'];
      //cuando el numero de indentificacion sea igual a 9 se manda llamar el api
      if (val.length === 9) {
        setLoaderInput(true);
        const { data, success } = await apiGetPersona({
          identificacion: val,
          tipo: tipoID ? tipoID : '0',
        });
        let commonData = {};

        if (success) {
          setLoaderInput(false);
          if (data.telefono) {
            setMaskTel('****-####');
          }

          setRecuperoContacto(true);

          commonData = {
            tipoIdentificacion: renderValueIdentidad(
              data.tipoIdentificacion
                ? data.tipoIdentificacion
                : formData.tipoIdentificacion || '0',
            ),
            numeroIdentificacion: val,
            primerNombre: capitalizarPalabra(data.primerNombre) || '',
            segundoNombre: capitalizarPalabra(data.segundoNombre) || '',
            primerApellido: capitalizarPalabra(data.primerApellido) || '',
            segundoApellido: capitalizarPalabra(data.segundoApellido) || '',
            correo: data.correo,
            telefono: data.telefono,
            fechaNacimiento: data?.fechaNacimiento || '',
            edad: calcularEdad(data?.fechaNacimiento) || '',
          };
        } else {

          setRecuperoContacto(false);

          commonData = {
            tipoIdentificacion: renderValueIdentidad(tipoID ? tipoID : '0'),
            numeroIdentificacion: val,
            primerNombre: '',
            segundoNombre: '',
            primerApellido: '',
            segundoApellido: '',
            correo: '',
            telefono: '',
            fechaNacimiento: '',
            edad: '',
          };

          setFormDataError({});
          setModal(true);
          setAlert({
            title: '¡Información no encontrada!',
            description:
              'No pudimos completar automáticamente tus datos. Asegúrate de llenar todos los campos obligatorios antes de continuar.',
            severity: 'warning',
          });
          setTimeout(() => {
            setModal(false);
          }, 4000);
        }

        setFormData(prev => {
          if (dataCotizar.seguro.codigo === 'SEG-V') {
            // eslint-disable-next-line no-unused-vars
            const { fechaViaje, ...rest } = prev;
            return {
              ...rest,
              ...formData,
              ...commonData,
            };
          }
          return {
            ...prev,
            ...formData,
            ...commonData,
          };
        });

        setLoaderInput(false);
        setSwitchMostrarCampos(true);
      }

      return;
    }

    if (valForm === 'tipoIdentificacion') {
      const commonData = {
        tipoIdentificacion: val,
        numeroIdentificacion: '',
        primerNombre: '',
        segundoNombre: '',
        primerApellido: '',
        segundoApellido: '',
        correo: '',
        telefono: '',
      };

      if (dataCotizar.seguro.codigo === 'SEG-V') {
        setFormData({
          ...formData,
          ...commonData,
          fechaNacimiento: '',
          edad: '',
        });
      } else {
        setFormData({
          ...formData,
          ...commonData,
        });
      }
      return;
    }

    // cuando se ingrese la fecha de nacimiento ejecutaremos funcion para calcular la edad y llenar el campo de edad
    if (valForm === 'fechaNacimiento') {
      const edadCalculada = calcularEdad(val);

      setFormData({
        ...formData,
        edad: edadCalculada,
        fechaNacimiento: val,
      });

      return;
    }

    if (valForm === 'primerNombre' || valForm === 'primerApellido') {
      if (val.length !== '') {
        setFormDataError({ ...formDataError, [valForm + 'Error']: false });
      }
    }

    if (valForm === 'correo') {
  

      if(val.length !==0){
        setFormDataError({ ...formDataError, [valForm + 'Error']: false });
      }
  


      let newData = { ...formData };
      newData[valForm] = val;
      setFormData(newData);
      setMaskedData(prev => ({ ...prev, correo: val }));
      return;
    }

    if (valForm === 'telefono') {
      if (val.length !== 0) {
        setFormDataError({ ...formDataError, [valForm + 'Error']: false });
      }

      if (!val.includes('*')) {
        setMaskTel('####-####');
        if (val.length === 9) {
          setFormData(prev => ({ ...prev, telefono: val }));
        }
      }
      if (val.length === 0) {
        formData.telefono = '';
        setMaskTel('####-####');

        return;
      }

      return;
    }

    let newData = { ...formData };
    newData[valForm] = val;
    setFormData(newData);

  };

  const handleSubmit = async () => {
    setIsRecuperandoCotizacion(false);
    setComprandoOtroSeguro(false);

    const token = await recaptchaRef.current.executeAsync();

    if (token) {
      if (handleValidForm()) {
        //se establace en false porque debe de seguir el fulujo normal de la cotizacion

        dataCotizar.datosPersonales = {
          ...formData,
          ...maskedData,
        };
        try {
          const nombreCompletoPersona = `${dataCotizar.datosPersonales?.primerNombre} ${dataCotizar.datosPersonales?.segundoNombre} ${dataCotizar.datosPersonales?.primerApellido} ${dataCotizar.datosPersonales?.segundoApellido}`;

          const params = {
            codigoCategoria: dataCotizar.seguro.codigo,
            dataPersonal: { ...dataCotizar.datosPersonales, ...maskedData },
            dataSeguro: dataCotizar.dataSeguro,
            codigoAliado: dataCotizar.aliado,
            tipo: 'COTIZADOR',
            dataOportunidad: {
              codigoEtapa: 'COT',
              mensajeBitacora: `Se ha creado una cotización de ${dataCotizar.seguro.nombre} para ${nombreCompletoPersona}.`,
              idGoogle: obtenerClientID(),
            },
            tokenReCaptcha: token,
          };

          setCargando(true);

          const { success, data, statusCode } = await apiCrearDataCRM(params);

          if (success) {
            setIdCotizacion(data.cotizacion);
            setDetallesCotizacion(data.detalles);
            setIdOportunidad(data.oportunidad);
            setPromocion(data.promocionViaje);

            handleSiguientePaso();
          } else {
            if (statusCode === 400) {
              setAlert({
                title: data.response.data?.error,
                description: data.response.data?.message,
                severity: 'error',
              });
              setModal(true);
              setCargando(false);
            } else {
              navigate('/page-error-500');
            }
          }
        } catch (error) {
          console.log('error peticion: ', error);
        } finally {
          setCargando(false);
        }
      }
    }
  };

  const handleValidForm = () => {
    let isValid = true;
    let validaciones = [];
    const dataKeys = Object.keys(formData);

    dataKeys.forEach(dataKey => {
      const valor = formData[dataKey];

      pasos.forEach(paso => {
        paso.preguntas.forEach(p => {
          validaciones = validaciones.concat(
            formValidDatosPersonales(p, valor, dataKey),
          );
        });
      });
    });

    const camposConError = validaciones
      .filter(({ campo }) => {
        return campo !== 'fechaViaje';
      })
      .map(validacion => validacion.campo);

    const formDataErrors = dataKeys.reduce((errors, dataKey) => {
      const campoTieneError = camposConError.includes(dataKey);
      return { ...errors, [`${dataKey}Error`]: campoTieneError };
    }, {});

    setFormDataError(formDataErrors);


    //Esto se hizo ya que se tuvo problemas con el campo de fecha de viaje parece que se traiga desde varios formularios atras y lo validaba cuando no es asi.
    validaciones = validaciones.filter(({ campo }) => {
      return campo !== 'fechaViaje';
    });
    isValid = validaciones.length === 0;

    for (let i = 0; i < dataKeys.length; i++) {
      const dataKey = dataKeys[i];
      const valor = formData[dataKey];

      if (dataKey === 'edad' && dataCotizar.seguro.codigo === 'SEG-V') {
        const edad = valor.split(' ');
        if (Number(edad[0]) < 16 && edad[1] === 'días') {
          setAlert({
            title: '¡Edad mínima de 16 días!',
            description:
              'El viajero debe tener al menos 16 días de edad para obtener una cotización de seguro de viaje. Cumple este requisito para continuar.',
            severity: 'warning',
          });
          setModal(true);
          return false;
        }
      }
    }

    return isValid;
  };

  const renderValueIdentidad = identificacion => {
    switch (identificacion) {
      case 'Nacional':
        return '0';
      default:
        return identificacion;
    }
  };

  return (
    <>
      <div className="mx-auto w-full mb-10 container-datos-personales px-6 md:px-0 xl:px-56 alto-pantalla ">
        {comprandoOtroSeguro && <ComprandoOtroSeguroViaje />}

        <TextCustom
          text="Datos Personales"
          variant="h2"
          component="h2"
          className="fontPSemiBold color-general text-[22px] md:text-4xl text-center mb-3"
        />
        <TextCustom
          text={`Para la cotización de tu ${dataCotizar.seguro.nombre} es necesario que todos los datos estén llenos.`}
          variant="p"
          component="p"
          className="fontPRegular color-general text-center text-sm md:text-base"
        />

        <div className="text-center flex flex-wrap grid-form relative">
          {pasos[paso - 1]?.preguntas.map((pregunta, index) => {
            return (
              <PreguntasForm
                titulo={pregunta.titulo}
                tipoDato={pregunta.tipo_dato.tipo}
                valueForm={pregunta.value}
                action={handleValue}
                mostrarError={formDataError[pregunta.value + 'Error']}
                msgError={pregunta.msgError}
                placeholder={pregunta.placeholder}
                respuestas={pregunta?.respuestas}
                key={index}
                className={'form-left'}
                descripcion={pregunta?.descripcion}
                value={formData[pregunta.value]}
                numeroElemento={index + 1}
                mostrarCampos={mostrarCampos}
                loader={loaderInput}
                alignError="left"
                mostrarRequerido={true}
                requerido={pregunta?.requerido}
                formatTel={maskTel}
                classNamePrincipal={
                  pregunta.value === 'beneficiarios'
                    ? 'w-full'
                    : 'w-full lg:w-[48%] h-auto'
                }
              />
            );
          })}
        </div>
        <div className="flex flex-col justify-center items-center ">
          <ButtonCustom
            text="Confirmar"
            width="180px"
            onClick={handleSubmit}
            disabled={!mostrarCampos}
          />
          <ButtonCustom
            text="Regresar"
            variant="text"
            className="color-general fontPSemiBold text-base  mt-3 cursor-pointer underline  lg:text-lg hover:bg-transparent"
            onClick={handleRegresarPaso}
            typeColor="general"
          />
        </div>
      </div>
    </>
  );
};

export default PasoPersonal;
